<template>
  <b-card title="Klaim Detail"> </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
  },
};
</script>

<style>
</style>
